<template>
  <div v-if="finalImageUrl" class="advertisement">
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeB6TUJc45euf_Mi8jAEpOZcKV3kJWd8z-8R9m-_nC5Py7Cow/viewform?usp=sf_link" target="_blank">
      <img :src="finalImageUrl" alt="Advertisement" />
    </a>
  </div>
</template>

<script>
import defaultAdImage from '../assets/images/basead.png';

export default {
  name: 'AdvertisementComponent',
  props: {
    imageUrl: {
      type: String,
      default: defaultAdImage
    }
  },
  computed: {
    finalImageUrl() {
      return this.imageUrl || defaultAdImage;
    }
  }
};
</script>

<style scoped>
.advertisement img {
  max-width: 95%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.advertisement img:hover {
  transform: translateY(-10px); /* Shifts the image up on hover */
}
</style>