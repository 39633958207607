<!-- AssemblyNotes.vue -->
<template>
    <div class="assembly-notes">
      <h2>Assembly Notes</h2>
      <ul v-if="notesArray.length">
        <li v-for="(note, index) in notesArray" :key="index">{{ note }}</li>
      </ul>
      <p v-else>No notes available at this time.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AssemblyNotes',
    props: {
      notes: {
        type: String,
        default: ''
      }
    },
    computed: {
      notesArray() {
        return this.notes.split('\n').map(note => note.trim()).filter(Boolean);
      }
    }
  };
  </script>
  
  <style scoped>
  .assembly-notes {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    font-family: 'Roboto Mono', monospace;
  }
  
  .assembly-notes h2 {
    margin-bottom: 10px;
  }
  
  .assembly-notes ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  @media only screen and (max-width: 500px) {
    .assembly-notes {
      padding: 10px;
    }
  }
  </style>